import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useCallback } from 'react';
import { getMetadata } from 'helpers';
import { PageTemplate } from 'gatsby-theme-ww-prismic/src/templates/page';
export var ButtonNavigationTemplate = function ButtonNavigationTemplate(_ref) {
  var _metadata$primary, _metadata$primary$nav, _metadata$primary2, _metadata$primary2$bu;

  var data = _ref.data,
      location = _ref.location,
      props = _objectWithoutPropertiesLoose(_ref, ["data", "location"]);

  var page = data === null || data === void 0 ? void 0 : data.prismicPage;
  var metadata = getMetadata('PrismicPageMainButtonNavigation', page);
  var navigationDoc = metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$nav = _metadata$primary.navigation) === null || _metadata$primary$nav === void 0 ? void 0 : _metadata$primary$nav.document;
  var buttonsNavigationDoc = metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$bu = _metadata$primary2.button_navigation) === null || _metadata$primary2$bu === void 0 ? void 0 : _metadata$primary2$bu.document;
  var injectSlices = useCallback(function (list) {
    if (list === void 0) {
      list = [];
    }

    return [{
      __typename: 'PageBodyHeader',
      id: 'header'
    }, {
      __typename: 'PageBodyButtonNavigation',
      id: 'buttonNavigation',
      primary: {
        navigation: {
          document: navigationDoc
        },
        button_navigation: {
          document: buttonsNavigationDoc
        }
      }
    }].concat(_toConsumableArray(list), [{
      __typename: 'PageBodyFooter',
      id: 'footer'
    }]);
  }, [navigationDoc, buttonsNavigationDoc]);
  return React.createElement(PageTemplate, Object.assign({
    data: data,
    location: location,
    listMiddleware: injectSlices
  }, props));
};
export default ButtonNavigationTemplate;
export var query = "1604691817";